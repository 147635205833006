import React from "react";
import { Container, Col, Row, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { TwitterShareButton, TwitterIcon } from 'react-share';
import CopyToClipboard from "react-copy-to-clipboard";
import { shareKakao } from "./shareKakao";

class Start extends React.Component {


  // 설문지 렌더링
  render() {
    let domain = "https://healthchecker.swygbro.com"
    return (
        <div>
          <div className="main">
            <Container>
            <Row>
                <Col md={4}></Col>
                <Col md={4} className="text-center">
                <div className="start-title">
                    <span id="start-title-sub-a">총 문항 수 11개, 검사시간 약 10초</span>
                    <img id="logo" src="logo.png"></img>
                    <div id="start-title-sub-b">10초만에 알아보는 나의 현재 종합 건강 레벨</div>
                </div>
                <div>
                    <img className="result-img" src="start-img.gif"></img>
                </div>
                <div>
                    <Link to="/test">
                    <Button type="button" id="start-btn">테스트 시작</Button>
                    </Link>
                </div>
                <div className="share">
                    <div className="share-btn">
                    <TwitterShareButton url={domain} title={"나의 몸과 정신 건강은?"} hashtag="#swyg">
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>
                    </div>
                    <div className="share-btn">
                    <CopyToClipboard text={domain}>
                        <img className="share-img"  src={"/link.png"} alt="url" />
				            </CopyToClipboard>
                    </div>
                    <div className="share-btn">
                        <img className="share-img" src={"/kakao.png"} alt={"Kakao Logo"} 
                        onClick={() => shareKakao(domain, "", "i.jpg")}/>
                    </div>
                  </div>
                </Col>
                <Col md={4}></Col>
            </Row>  
            </Container>
          </div>
        </div>
    )
  }
}

export default Start;