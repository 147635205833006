import React from "react";
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from './App.js';
// import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss'



// ========================================
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);