import React from "react";
import { Container, Col, Row, Button, ProgressBar} from "react-bootstrap";

class Test extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFirstQuestion: true, 
      age: 0,
      page: 1,
      question: "귀하의 연령대는?",
      options: [[0, 1, '10대', ''],
                [1, 2, '20대', ''],
                [2, 3, '30대', ''],
                [3, 4, '40대', ''],
                [4, 5, '50대', '']],
      score: 0,
      bodyScore: 0,
      mindScore: 0,
      answers: ""
    };

    this.contents = this.props.contents;
    this.pageLen = 12;
    this.handleClick = this.handleClick.bind(this);
  }

  // 클릭 이벤트. 다음 질문으로 update
  handleClick(e) {
    e.preventDefault();
    let selectedIdx = e.target.value;
    let score = this.state.options[selectedIdx][1];

    // 점수 합산
    if (this.state.isFirstQuestion) {   // 첫 질문이면 연령만 저장
      this.setState({
        isFirstQuestion: false, 
        age: selectedIdx});
    } else {                            // 연령 질문 아니면 점수 합산
      let newScore = this.state.score + score;
      let newBodyScore = this.state.bodyScore;
      let newMindScore = this.state.mindScore;

      if (this.state.page < 10) {
        newBodyScore += score;
      } else {
        newMindScore += score;
      }

      let newAnswers = [this.state.answers, selectedIdx].join(',');
      this.setState({
        score: newScore,
        bodyScore: newBodyScore,
        mindScore: newMindScore,
        answers: newAnswers
      })

      // 결과 페이지
      if (this.state.page === this.pageLen) {
        newAnswers = newAnswers.substring(1);
        window.location.replace(`/result?age=${this.state.age}&score=${newScore}&bodyScore=${newBodyScore}&mindScore=${newMindScore}&answers=${newAnswers}`);
      }
    }

    // 질문 변경

    this.setState({
      page: this.state.page + 1,
      options: this.contents[this.state.page - 1]["options"],
      question: this.contents[this.state.page - 1]["question"]
    });
  }

  // 설문지 렌더링
  render() {
    const options = this.state.options;
    const buttons = options.map((opt) =>
       <button className="btn-test" onClick={this.handleClick} key={opt[0]} value={opt[0]}>{opt[2]}</button>
    )
    // this.setState

    return (
      <div>
        <div className="nav">
          <a href="/"><img id="logo-nav" src="logo.png"></img></a>
        </div>
        <div>
          <ProgressBar now={this.state.page * 9} />
        </div>
        <div className="main">
          <Container>
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <div>
                  <div className="text-right">
                    <p className="text-secondary">
                      {this.state.page}/{12}
                    </p>
                  </div>
                  <h2 className="question">{this.state.question}</h2>
                  <div className="answer">
                    {buttons}
                  </div>
                </div>
              </Col>
              <Col md={4}></Col>
            </Row>  
          </Container>
        </div>
      </div>
    );
  }
}

export default Test;