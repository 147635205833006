import React from "react";
import { Container, Col, Row, ProgressBar } from "react-bootstrap";
import { TwitterShareButton, TwitterIcon } from 'react-share';
import CopyToClipboard from "react-copy-to-clipboard";
import { shareKakao } from "./shareKakao";
import { Link } from "react-router-dom";

class Result extends React.Component {
    constructor(props) {
        super(props)

        this.contents = this.props.contents;
    }
    
    getUrlParams(key) {
        const sch = window.location.search;
        const params = new URLSearchParams(sch);

        return params.get(key);
    }

    getAnswers() {
        return this.getUrlParams('answers').split(",");
    }

    getLevelImg() {
        let levelIdx = this.getLevel("all");

        let lv1 = require("./assets/lv1.svg").default;
        let lv2 = require("./assets/lv2.svg").default;
        let lv3 = require("./assets/lv3.svg").default;
        let lv4 = require("./assets/lv4.svg").default;
        let lv5 = require("./assets/lv5.svg").default;
        let lv = [lv1, lv2, lv3, lv4, lv5];

        return lv[levelIdx-1];
    }

    getLevel(type) { 
        // URL Param에서 점수 파싱
        let [age, score] = [this.getUrlParams('age'), this.getUrlParams('score')];
        let [bodyScore, mindScore] = [this.getUrlParams('bodyScore'), this.getUrlParams('mindScore')];
        if (age == null || score == null || bodyScore == null || mindScore == null) {
            alert("유효하지 않은 페이지 입니다.");
            window.location.replace("/");
        }

        // 점수판 선언
        let levelBoard = [
            [40, 60, 70, 110, 150], // 10대
            [40, 60, 70, 100, 150], // 20대
            [30, 50, 70, 100, 150], // 30대
            [30, 50, 70, 90, 150],  // 40대
            [20, 40, 60, 80, 150]   // 50대
        ]

        let bodyLevelBoard = [
            [30, 50, 60, 90, 150], // 10대
            [30, 50, 60, 90, 150], // 20대
            [30, 50, 60, 90, 150], // 30대
            [20, 50, 40, 70, 150], // 40대
            [20, 50, 40, 70, 150]  // 50대
        ]

        let mindLevelBoard = [
            [5, 9, 15, 19, 150], // 10대
            [5, 9, 15, 19, 150], // 20대
            [5, 9, 15, 19, 150], // 30대
            [5, 9, 15, 19, 150], // 40대
            [5, 9, 15, 19, 150]  // 50대
        ]


        // 계산 유형에 따라 분리
        // all - 종합, body - 신체, mind - 정신
        if (type === "body") {
            levelBoard = bodyLevelBoard;
            score = bodyScore;
        } else if (type === "mind") {
            levelBoard = mindLevelBoard;
            score = mindScore;
        }

        // LEVEL 계산
        for (let i=0; i < levelBoard[0].length; i++) {
            if (score <= levelBoard[age][i]) {
                return i+1;
            }
        }
        return levelBoard[0].length;
    }

    getResultImg() {
        let answers = this.getAnswers().map((a, idx) => 
            parseInt(String(idx + 2) + String(parseInt(a) + 1))
        );

        // 결과 이미지
        let a = require("./assets/a.jpg");
        let b = require("./assets/b.jpg");
        let c = require("./assets/c.jpg");
        let d = require("./assets/d.jpg");
        let e = require("./assets/e.jpg");
        let f = require("./assets/f.gif");
        let g = require("./assets/g.jpg");
        let h = require("./assets/h.jpg");
        let i = require("./assets/i.gif");
        let j = require("./assets/j.jpg");
        let k = require("./assets/k.jpg");

        let resultImg = [a, b, c, d, e, f, g, h, i, j, k];

        // 답변에 포함되지 않을 경우 해당 idx 타이틀이 결과 제목이 됨
        let answerBoard = [
            [23,24,33,34,42,43,44,73,103,104],
            [23,24,33,34,73,101,102],
            [23,24,33,34,53,54,63,64,73],
            [34,73,92,93,94],
            [23,24,33,34,42,43,44,73,101,102],
            [101,102,111,112,115,121,122],
            [21,22,51,53,61,62,101,111,112],
            [23,24,113,114,124],
            [23,24,33,34,73,103,104,113,114,124],
            [23,24,33,34,43,44,63,64,73],
            []
        ]

        let checkSubset = (parentArray, subsetArray) => {
            return subsetArray.every((el) => {
                return !parentArray.includes(el)
            })
        }

        for (let i=0; i<answerBoard.length; i++) {
            if (checkSubset(answerBoard[i], answers)) {
                return [resultImg[i], i];
            }
        }
       
        return [resultImg[-1], resultImg.length - 1];
    }
 
    getComments() {

        let comments = [];
        let answers = this.getAnswers();

        if (answers.length !== this.contents.length) {
            alert("유효하지 않은 페이지 입니다.");
            window.location.replace("/");
        }

        for (let i=0; i<this.contents.length; i++) {
            comments.push(this.contents[i]["options"][answers[i]][3]);
        }

        return comments;        
    }


    
    render() {
        let allLv = this.getLevel("all");
        let bodyLvInt = this.getLevel("body");
        let bodyLv = Array.from(Array(5).keys()).map(function(i) {
            if (i + 1 <= bodyLvInt) {
                return <div className="lv-rect-pink"  key={i}></div>
            } else {
                return <div className="lv-rect-gray"  key={i}></div>
            }
        });
        let mindLvInt = this.getLevel("mind");
        let mindLv = Array.from(Array(5).keys()).map(function(i) {
            if (i + 1 <= mindLvInt) {
                return <div className="lv-rect-pink"  key={i}></div>
            } else {
                return <div className="lv-rect-gray"  key={i}></div>
            }
        });

        let comments = this.getComments();
        let commentsEle = comments.map(function(comm, index) {
            if (index === 0) {
                return <div key={index} className="text-bold">{comm}</div>;
            } else {
                return <div key={index}>{comm}</div>;
            }
        }
        );
        let [resultImg, imgIdx] = this.getResultImg();
        let lvImg = this.getLevelImg();

        return (
        <div>
          <div className="nav">
            <a href="/"><img id="logo-nav" src="logo.png"></img></a>
          </div>
          <div>
            <ProgressBar now={100} />
          </div>
        <div className="result">
            <Container>
            <Row>
                <Col md={4}></Col>
                <Col md={4} id="result-col">

                {/* LEVEL 조회 */}
                  <div className="result-level">
                    <span id="result-level-title">나의 종합점수는 </span>
                    <span id="result-level-title-back"></span>
                    <img src={lvImg} className='result-level-img' alt='level' />
                    <span id="result-level-all"> 만점 LV5</span>
                  </div>

                {/* 신체 & 건강 레벨 */}
                <div className="lv-detail">
                    
                    <div className="lv-detail-row">
                        <span className="lv-detail-title">신체</span>
                        {bodyLv}
                    </div>
                    <div className="lv-detail-row">
                        <span className="lv-detail-title">정신</span>
                        {mindLv}
                    </div>
                </div>


                {/* 결과 이미지 */}

                  <div>
                    <img src={resultImg} className='result-img' alt='result' />
                  </div>

                {/* 결과 공유하기 */}

                  <div className="share">
                    <div className="share-btn">
                    <TwitterShareButton url={window.location.href}
                            title={"나의 몸과 정신 건강은 LV" + allLv}
                            hashtag="#swyg #종합건강테스트">
                        <TwitterIcon size={40} round />
                    </TwitterShareButton>
                    </div>
                    <div className="share-btn">
                    <CopyToClipboard text={window.location.href}>
                        <img className="share-img"  src={"/link.png"} alt="url" />
				    </CopyToClipboard>
                    </div>
                    <div className="share-btn">
                        <img className="share-img" src={"/kakao.png"} alt={"Kakao Logo"} 
                            onClick={() => shareKakao(window.location.href, "LV"+allLv, 
                                    String.fromCharCode(97+imgIdx)+".jpg" // 이미지 URL
                                )}
                            />
                    </div>
                    <div className="share-btn">
                        <a className="share-btn-circle" href={resultImg} download>
                            <img className="share-img" src="/downloading.png" alt="Download" />
                        </a>
                    </div>

                  </div>
                  <div>
                    <Link to="/">
                      <button type="button" id="restart-btn">테스트 다시하기</button>
                    </Link>
                  </div>

                {/* 결과 공유하기 */}
                  <div className="result-comments">
                    {commentsEle}
                  </div>


                </Col>
                <Col md={4}></Col>
            </Row>  
            </Container>
        </div>
        </div>
        );
    }
}

export default Result;