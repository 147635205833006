import { Route, Routes } from "react-router-dom";
import Test from "./test.js";
import Result from "./result.js";
import Start from "./start.js";

const contents = [
    {page: 2, question: '나는 아침에 일어나는 게 힘들다.', options: [
      [0, 2, '주 5일 이상', '만성피로에 찌들었군요. 건강한 삶을 위해 7시간 이상의 규칙적인 수면 습관을 길러요.'], // idx, 점수, 답변
      [1, 4, '주 3일 정도', '곧 만성피로증후군. 적절한 수면 습관이 필요해요.'],
      [2, 8, '주 1일 정도', '이 정도면 건강한 현대인!'],
      [3, 10, '365일 거의 매일 개운하다.', '당신 혹시 AI휴먼인가요? ']]},
    {page: 3, question: '나는 일상생활에서 피로함을 느낀다.', options: [
      [0, 2, '주 5일 이상', '✓ 혹시 당신의 얼굴에 턱 끝까지 내려온 다크서클이 있진 않은가요? '],
      [1, 4, '주 3일 정도', '✓ 당신은 평범한, 하지만 그리 건강하지 못한 피로에 찌든 현대인입니다.'],
      [2, 6, '주 2일 정도', '✓ 만성피로증 없음! 바이오리듬이 양호합니다!'],
      [3, 15, '전혀', '✓ 피로 따위 없는 강철 체력을 가졌군요.']]},
    {page: 4, question: '나는 숙취가 심하다.', options: [
      [0, 2, '하루 이상 힘들다', '✓ 절주! 금주! 삶이 아무리 고달파도 알코올은 절대 삼가세요!'],
      [1, 6, '반나절이면 회복한다', '✓ 아직 싱싱한 간을 지녔어요. 그래도 과음은 절대 안돼!'],
      [2, 10, '숙취가 뭐죠?', '✓ 건강한 간을 가진 당신은 행운아네요.'],
      [3, 10, '술 안마셔서 해당 없다', '✓ 금주를 하고 있다니, 건강한 생활습관이예요.']]},
    {page: 5, question: '나는 평소 하는 운동이 있다.', options: [
      [0, 1, '거의 안한다', '✓ 심각한 운동 부족이예요. 바빠도 운동은 꾸준히 꼭 하길 권장합니다.'],
      [1, 3, '보름에 한번 정도', '✓ 거의 운동을 하지 않는군요. 주 2회 운동은 꾸준히 해나가요.'],
      [2, 7, '주 2일 정도', '✓ 적절한 운동 습관을 가지고 있어요.굳.'],
      [3, 10, '주 5일 이상', '✓ 운동 중독! 건강은 내 삶의 원동력! 내 몸은 내가 챙긴다!']]},
    {page: 6, question: "나는 스쿼트를 할 수 있다.", options: [
      [0, 1, "한번에 10개 이하", '✓ 혹시 당신은 최약체,,,?'], 
      [1, 4, "한번에 30개 이상", '✓ 하체 부실,,, 하체 근육이 부족합니다.'], 
      [2, 6, "한번에 60개 이상", '✓ 튼튼한 하체의 소유자. 하체 건강 아주 우수합니다. '], 
      [3, 10, "한번에 100개 이상", '✓ 혹시 태릉선수촌 출신이신가요? ']]},
    {page: 7, question: "몸의 근육이 아프고 무겁다.", options: [
      [0, 1, "매일 그렇다", '✓ 정밀검사가 필요할 것 같아요, 한번 병원에서 종합검진을 받아보는 건 어떨까요!'], 
      [1, 4, "때때로 그렇다", ''], 
      [2, 10, "그렇지 않다", '']]},
    {page: 8, question: "하루에 물을 1리터 이상 꼭 챙겨먹는 편이다", options: [
      [0, 10, "그렇다", ''], 
      [1, 1, "아니다", '✓ 몸에 수분이 너무 부족해요. 하루 1.5리터의 수분 보충을 해주세요.']]},
    {page: 9, question: "상처가 잘 낫지 않는다", options: [
      [0, 1, "그렇다", '✓ 피부 노화가 진행 중이예요. 콜라겐 섭취도 필요한 때입니다!'],
      [1, 10, "아니다", '']]},
    {page: 10, question: "나는 스트레스를 잘 받는다.", options: [
      [0, 2, "거의 매일", '✓ 스트레스 받을 때는 쉼호흡 가다듬고 잠깐 부정적인 생각에서 멀어져봐요. 다 잘될거예요.'],
      [1, 4, "매일은 아니지만 잘 받는다", '✓ 스트레스 관리가 필요해요. 건강한 취미를 가져보세요. '],
      [2, 6, "때때로 받는다", '✓ 스트레스 관리가 필요해요. 건강한 취미를 가져보세요. '],
      [3, 10, "나는 늘 평안하다", '✓ 피쓰! 내 멘탈은 우주에 있다. 아무도 날 건드릴 수 없다. 내 감정은 내가 컨트롤한다. ']]},
    {page: 11, question: "나는 거울  내 모습을 보면", options: [
      [0, 0, "거울보기 싫어서 잘 보지 않는다", '✓ 나를 잘 가꿔주세요. 당신은 세상에서 유일하고 소중한 존재랍니다.'],
      [1, 2, "못생겨서 짜증난다", '✓ 나를 잘 가꿔주세요. 당신은 세상에서 유일하고 소중한 존재랍니다.'],
      [2, 10, "완벽하진 않아도 이정도면 괜찮은 정도라고 생각한다", ''],
      [3, 8, "늘 짜릿해, 내 외모에 내가 반한다", '✓ 건강한 멘탈을 가지셨군요! '],
      [4, 6, "아무 생각 없다", '✓ 자아도취에 빠진 당신, 혹시 너무 과하진 않은가요?']]},
    {page: 12, question: "나는 주변에", options: [
      [0, 1, "불행한 사람들로 가득차 있다", '✓ OMG,, 세상을 보는 시야를 좀 더 환하게 바꿔볼까요.'],
      [1, 3, "나보다 잘난 사람들로 가득차 있다", '✓ 당신도 충분히 소중한 사람입니다. Love your self. '],
      [2, 6, "아무 관심이 없다", '✓ 주변을 조금 둘러보는 시야도 가져볼까요.'],
      [3, 10, "내가 좋아하는 사람들로 가득차 있다", '✓ 주변이 꽃밭이군요. 당신의 주변에서는 좋은 향기가 나겠어요.']]}
  ];

const App = () => {
    return (
      <Routes>
        <Route path="/" element={<Start contents={contents}/>} />
        <Route path="/test" element={<Test contents={contents}/>} />
        <Route path="/result" element={<Result contents={contents}/>} />
      </Routes>
    );
  };
  
  export default App;