export const shareKakao = (route, level, imagePath) => { // url이 id값에 따라 변경되기 때문에 route를 인자값으로 받아줌
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init("b1badc139fa8eb16400cc6f2b48cba68"); // 카카오에서 제공받은 javascript key를 넣어줌 -> .env파일에서 호출시킴
      }
  
      // alert("나의 몸과 정신 건강은? " + level);
      // alert("https://healthchecker.swygbro.com/" + imagePath);

      kakao.Share.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
        content: {
          title: "나의 몸과 정신 건강은? " + level, // 인자값으로 받은 title
          description: "10초 만에 나의 건강을 체크해보자!", // 인자값으로 받은 title
          imageUrl: "https://healthchecker.swygbro.com/" + imagePath,
          link: {
            mobileWebUrl: route, // 인자값으로 받은 route(uri 형태)
            webUrl: route
          }
        },
        
        buttons: [
          {
            title: "나의 건강 상태 확인하기",
            link: {
              mobileWebUrl: route,
              webUrl: route
            }
          }
        ]
      });
    }
  };